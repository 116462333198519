import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Alert, Button, Card, Col, Input, List, Menu, Row, Tabs, Dropdown, Badge } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Address, AddressInput } from "../components";
import { ethers } from "ethers";
const { TabPane } = Tabs;

function FancyLoogiePreview({
  DEBUG,
  readContracts,
  writeContracts,
  tx,
  address,
  updateBalances,
  setUpdateBalances,
  nfts,
  nftsSvg,
  fancyLoogiesNfts,
  selectedFancyLoogiePreview,
  setSelectedFancyLoogiePreview,
  selectedNfts,
  setSelectedNfts,
  setFancyLoogiesNfts,
  fancyLoogiePreviewActiveTab,
  setFancyLoogiePreviewActiveTab,
  fancyLoogieContracts,
}) {
  const selectedFancyLoogie = useParams().id;

  const [fancyLoogieNfts, setFancyLoogieNfts] = useState();

  useEffect(() => {
    const updatePreview = async () => {
      if (DEBUG) console.log("Updating preview...");
      if (selectedFancyLoogie && readContracts.FancyLoogie) {
        let nftUpdate = {};
        const loogieSvg = await readContracts.FancyLoogie.renderTokenById(selectedFancyLoogie);
        let nftsSvg = "";
        for (const nft of nfts) {
          if (selectedNfts[nft]) {
            nftsSvg += await readContracts[nft].renderTokenById(selectedNfts[nft]);
          }
          const svg =
            '<svg width="400" height="400" xmlns="http://www.w3.org/2000/svg">' + loogieSvg + nftsSvg + "</svg>";
          setSelectedFancyLoogiePreview(svg);
        }
      } else {
        setSelectedFancyLoogiePreview("");
      }
    };
    updatePreview();
  }, [address, readContracts.FancyLoogie, selectedFancyLoogie, selectedNfts, updateBalances]);

  useEffect(() => {
    const updateSelectedFancyLoogie = async () => {
      if (selectedFancyLoogie && readContracts.FancyLoogie) {
        const fancyLoogieNftsUpdate = {};
        for (let contractIndex = 0; contractIndex < fancyLoogieContracts.length; contractIndex++) {
          const contractAddress = fancyLoogieContracts[contractIndex];
          const nftId = await readContracts.FancyLoogie.nftId(contractAddress, selectedFancyLoogie);
          fancyLoogieNftsUpdate[contractAddress] = nftId.toString();
        }
        setFancyLoogieNfts(fancyLoogieNftsUpdate);
      }
    };
    updateSelectedFancyLoogie();
  }, [selectedFancyLoogie, readContracts.FancyLoogie, fancyLoogieContracts.length]);

  return (
    <>
      {selectedFancyLoogiePreview ? (
        <div class="fancy-loogie-preview">
          <Card
            style={{ width: 515 }}
            title={
              <div style={{ height: 45 }}>
                <span style={{ fontSize: 18, marginRight: 8 }}>Selected FancyLoogie #{selectedFancyLoogie}</span>
              </div>
            }
          >
            <div dangerouslySetInnerHTML={{ __html: selectedFancyLoogiePreview }}></div>
            <Tabs
              activeKey={fancyLoogiePreviewActiveTab}
              onChange={tab => setFancyLoogiePreviewActiveTab(tab)}
              type="card"
            >
              {nfts.map(function (nft) {
                return (
                  <TabPane tab={
                    <div>
                      <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" style={{ float: "left" }}>
                        { nftsSvg[nft] }
                      </svg>
                    </div>
                    }
                    key={"preview-" + nft}
                  >
                    {fancyLoogieNfts && fancyLoogieNfts[readContracts[nft].address] > 0 ? (
                      <div>
                        Wearing {nft} #{fancyLoogieNfts[readContracts[nft].address]}
                        <Button
                          className="action-inline-button"
                          onClick={async () => {
                            const txCur = await tx(writeContracts.FancyLoogie.removeNftFromLoogie(readContracts[nft].address, selectedFancyLoogie));
                            await txCur.wait();
                            setFancyLoogieNfts(prevState => ({
                              ...prevState,
                              [readContracts[nft].address]: 0,
                            }));
                            setUpdateBalances(updateBalances + 1);
                          }}
                        >
                          Remove {nft}
                        </Button>
                      </div>
                    ) : (
                      <div>
                        {selectedNfts[nft] ? (
                          <div>
                            <span>Previewing #{selectedNfts[nft]}</span>
                            {fancyLoogieNfts && fancyLoogieNfts[readContracts[nft].address] == 0 && (
                              <Button
                                type="primary"
                                className="action-inline-button"
                                onClick={async () => {
                                  const tankIdInBytes =
                                    "0x" + parseInt(selectedFancyLoogie).toString(16).padStart(64, "0");

                                  const txCur = await tx(
                                    writeContracts[nft]["safeTransferFrom(address,address,uint256,bytes)"](
                                      address,
                                      readContracts.FancyLoogie.address,
                                      selectedNfts[nft],
                                      tankIdInBytes,
                                    ),
                                  );
                                  await txCur.wait();
                                  setSelectedNfts(prevState => ({
                                    ...prevState,
                                    [nft]: null,
                                  }));
                                  setFancyLoogieNfts(prevState => ({
                                    ...prevState,
                                    [readContracts[nft].address]: selectedNfts[nft],
                                  }));
                                  setUpdateBalances(updateBalances + 1);
                                }}
                              >
                                Transfer
                              </Button>
                            )}
                          </div>
                        ) : (
                          <span>Select a {nft} to preview</span>
                        )}
                      </div>
                    )}
                  </TabPane>
                );
              })}
            </Tabs>
          </Card>
        </div>
      ) : (
        <div class="fancy-loogie-preview">
          <Card
            style={{ width: 515 }}
            title={
              <div style={{ height: 45 }}>
                <span style={{ fontSize: 18, marginRight: 8 }}>No FancyLoogie selected</span>
              </div>
            }
          >
            <svg width="400" height="400" xmlns="http://www.w3.org/2000/svg">
              <g id="eye1">
                <ellipse stroke-width="3" ry="29.5" rx="29.5" id="svg_1" cy="154.5" cx="181.5" stroke="#000" fill="#fff"/>
                <ellipse ry="3.5" rx="2.5" id="svg_3" cy="154.5" cx="173.5" stroke-width="3" stroke="#000" fill="#000000"/>
              </g>
              <g id="head">
                <ellipse fill="white" stroke-width="3" cx="204.5" cy="211.80065" id="svg_5" rx="62" ry="51.80065" stroke="#000"/>
              </g>
              <g id="eye2">
                <ellipse stroke-width="3" ry="29.5" rx="29.5" id="svg_2" cy="168.5" cx="209.5" stroke="#000" fill="#fff"/>
                <ellipse ry="3.5" rx="3" id="svg_4" cy="169.5" cx="208" stroke-width="3" fill="#000000" stroke="#000"/>
              </g>
            </svg>
            <div style={{ height: 90 }}>
              Select a FancyLoogie from the <strong>FancyLoogies</strong> Tab to wear.
            </div>
          </Card>
        </div>
      )}
    </>
  );
}

export default FancyLoogiePreview;
